<template>
  <div class="am-fp-test-drive-scheduling">
    <div>Wann wollen Sie probefahren?</div>
    <div>
      <div>
        <amui-input
          v-model="formData.date"
          label="Datum"
          :invalid="invalid.date"
          viewport="s"
          type="date"
          @input="onInputDate"
        />
      </div>
      <div>
        <amui-select
          v-model="formData.time"
          :options="timeOptions"
          label="Uhrzeit"
          aria-label="Uhrzeit"
          :invalid="invalid.time"
          viewport="s"
          @change="onChangeTime"
        >
        </amui-select>
      </div>
    </div>
  </div>
</template>
<script>
import { AmuiInput } from '@/../ui/components/input'
import { AmuiSelect } from '@/../ui/components/select'

export default {
  components: {
    AmuiSelect,
    AmuiInput
  },
  props: {
    date: {
      type: String,
      required: false,
      default: ''
    },
    time: {
      type: String,
      required: false,
      default: null
    },
    timeOptions: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      formData: {
        date: this.date,
        time: this.time
      },
      invalid: {
        date: false,
        time: false
      }
    }
  },
  watch: {
    date(value) {
      this.formData.date = value
    },
    time(value) {
      this.formData.time = value
    }
  },
  methods: {
    isValid() {
      this.invalid.date = this.formData.date.length === 0
      this.invalid.time =
        this.formData.time === null ||
        !this.timeOptions.map(o => o.value).includes(this.formData.time)

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    validate() {
      return {
        valid: this.isValid()
      }
    },
    onInputDate(value) {
      this.invalid.date = false
      this.$emit('update:date', value)
    },
    onChangeTime(value) {
      this.invalid.time = false
      this.$emit('update:time', value)
    }
  }
}
</script>
